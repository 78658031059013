<template>
  <div class="caution-rule" :style="cautionStyle"> &nbsp; </div>
</template>

<script>
export default {
  name: "CautionRule",
  props: {
    width: {
      type: String,
      required: false,
      default: '100%',
    },
    height: {
      type: String,
      required: false,
      default: '8px'
    },
    angle: {
      type: Number,
      required: false,
      default: 45
    },
    barWidth: {
      type: Number,
      required: false,
      default: 23
    }
  },
  computed: {
    cautionStyle () {
      return `
        height: ${this.height};
        width: ${this.width};
        background: repeating-linear-gradient(
          ${this.angle}deg,
          #ffff00,
          #ffff00 ${this.barWidth}px,
          #000000 ${this.barWidth}px,
          #000000 ${this.barWidth * 2}px
        );`
    }
  },
}
</script>

<style scoped>
  .caution-rule {
    display: block;
  }
</style>