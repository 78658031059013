<template>
<div id="compliance-container">
  <a
    href="https://www.hulu.com/terms"
    target="_blank"
  >
    Terms of Service
  </a>
  <a
      href="https://www.hulu.com/privacy"
      target="_blank"
  >
    Privacy Policy
  </a>
  <a
      href="https://www.hulu.com/ca-privacy-rights"
      target="_blank"
  >
    Your California Privacy Rights
  </a>
  <a
      href="https://info.evidon.com/pub_info/3920?v=1&nt=0&nw=false"
      target="_blank"
  >
    About Ads
  </a>
</div>
</template>

<script>
export default {
name: "Compliance"
}
</script>

<style scoped>
#compliance-container {
  width: calc(100% - 2rem);
  background: #292c33;
  padding: 1.5rem;
  margin-top: 3rem;
  margin-bottom: 30px;

  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;

  font-size: 0.85rem;
}

#compliance-container > a {
  color: white;
  text-decoration: none;
  font-family: sans-serif;
  transition: color .3s ease;

  margin: 1rem 0;

  vertical-align: center;
}

#compliance-container > a:hover {
  color: lightgrey;
}

@media screen and (min-width: 950px) {
  #compliance-container {
    flex-direction: row;
  }

  #compliance-container > a {
    margin: 0;
  }
}
</style>