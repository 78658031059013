<template>
  <div id="korvo-ad-flex-container">
    <img
      id="car-flash-mobile"
      src="../../assets/korvo-ad-car-flash-mobile.png"
      alt="Buy Korvo's Car!*"
    />
    <img
      id="car-flash-desktop"
      src="../../assets/korvo-ad-car-flash-desktop.png"
      alt="Buy Korvo's Car!*"
    />
    <img
      id="arrow-1"
      class="arrow"
      src="../../assets/rainbow-arrow.gif"
      alt="->"
    />
    <img
      class="arrow mobile"
      src="../../assets/rainbow-arrow.gif"
      alt="->"
    />
    <img
      class="arrow mobile"
      src="../../assets/rainbow-arrow.gif"
      alt="->"
    />
    <img
      id="auction-listing-mobile"
      src="../../assets/car-sold-mobile.png"
      alt="Sold!"
    />
    <img
        id="auction-listing-desktop"
        src="../../assets/car-sold-desktop.png"
        alt="Sold!"
    />
  </div>
</template>

<script>
export default {
  name: "KorvoAd"
}
</script>

<style lang="scss" scoped>
  #korvo-ad-flex-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
    order: 0;

    margin: 2rem 0 1rem;
    width: 100%;
    height: 40px;

    background: rgba(225,225,225,0.65);
  }

  #car-flash-desktop,
  #car-flash-mobile {
    position: relative;
  }

  #car-flash-mobile {
    transform: scale(1.4);
    left: -10px;
    margin-right: -15px;
    height: 100%;
  }

  #car-flash-desktop {
    display: none;
  }

  .arrow {
    display:block;
    height: calc(100% - 0.5rem);
    padding: 0.25rem;
  }

  #auction-listing-desktop,
  #auction-listing-mobile {
    position: relative;
    padding: 0.25rem;
  }

  #auction-listing-desktop {
    display: none;
  }

  #auction-listing-mobile {
    transform: scale(1.3);
    right: 0;
    top: -15px;
    height: 100%;
  }

  @media screen and (min-width: 450px) and (max-width: 949px) {
    #korvo-ad-flex-container {
      height: 55px;
    }
  }

  @media screen and (min-width: 950px) {
    #korvo-ad-flex-container {
      flex-direction: column;
      width: 200px;
      height: auto;
    }

    #car-flash-mobile {
      display: none;
    }

    #car-flash-desktop {
      display: initial;
      width: 100%;
      transform: scale(1.1);
      z-index: 1;
    }

    #arrow-1 {
      transform: rotate(90deg) translateX(-55px);
      height: auto;
      margin: 0 auto;
    }

    .mobile {
      display: none;
    }

    #auction-listing-mobile {
      display: none;
    }

    #auction-listing-desktop {
      display: initial;
      width: 100%;
      z-index: 2;
    }
  }

  @media screen and (min-width: 950px) and (max-width: 1249px) {
    #auction-listing-desktop {
      transform: scale(1.2) translateY(-15px);
    }
  }

  @media screen and (min-width: 1250px) {
    #arrow-1 {
      transform: rotate(90deg) scale(1.1) translateX(-45px);
    }

    #auction-listing-desktop {
      transform: scale(1.2) translateY(-15px);
    }
  }
</style>