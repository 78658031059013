<template>
  <div id="private-link-container">
    <div id="private-link-object">
      <div id="private-link-text">
        Private Link:
      </div>
      <a
          id="private-link-lost-image"
          href="#"
          @click="privateLinkClick"
      >
        <img
            id="lost-img-thumb"
            src="../../assets/broken-image-icon.png"
            alt="Image not found."
        />
        <div id="private-link-url">
          photo_20201208_235917.jpeg
        </div>
      </a>
    </div>
    <div
        v-if="imageShown"
        id="private-image-container"
        @click="hideImage"
    >
      <img
          id="private-pupa"
          src="../../assets/pupa-private-image.jpg"
          alt="Pupa's private image"
      />
      <div id="image-closer">
        &times;
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PrivateLink",
  data() {
    return {
      imageShown: false
    }
  },
  methods: {
    privateLinkClick() {
      this.imageShown = true;
    },
    hideImage() {
      this.imageShown = false;
    }
  }
}
</script>

<style scoped>
  #private-link-container {
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: flex-start;

    width: 100%;

    margin: 1rem 0;
  }

  @media screen and (min-width: 950px) {
    #private-link-container {
      position: absolute;
      top: 15px;
      left: 0;
      justify-content: initial;
      width: auto;
    }
  }

  #private-link-object {
    margin: 0;
    width: auto;
    padding-right: 2rem;
  }

  #private-link-text {
    color: red;
    letter-spacing: 1px;

    font-size: 7pt;
    font-weight: bold;
  }

  #private-link-lost-image {
    display: flex;
    flex-direction: row;
    align-items: center;

    padding: 3px 3px;

    background: white;

    border: 1px solid grey;
    border-radius: 0.15rem;
  }

  #lost-img-thumb {
    height: 10px;
    width: auto;
  }

  #private-link-url {
    display: block;
    margin-left: 0.5rem;
    font-size: 7pt;
    letter-spacing: 1px;
  }

  #private-image-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    display: flex;
    align-items: flex-start;
    width: 60%;

    z-index: 50;
    border: 3px solid black;

    cursor: pointer;
  }

  #private-pupa {
    width: 100%;
  }

  #image-closer {
    position: absolute;
    right: -15px;
    top: -15px;
    width: 30px;
    height: 30px;

    background: white;
    border: 2px solid black;
    border-radius: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center
  }

  @media screen and (min-width: 950px) {
    #private-image-container {
      width: auto;
    }

    #private-pupa {
      width: auto;
      height: 300px;
    }
  }
</style>