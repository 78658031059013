<template>
  <div id="web-design-expert-container">
    <img
      id="fam-headshot"
      src="../../assets/fam-photo.png"
      alt="The Solar Opposites family."
    />
    <p id="web-design-credits">
      * THIS SITE DESIGNED BY THE SOLAR OPPOSITES
    </p>
  </div>
</template>

<script>
export default {
name: "WebDesignExpert"
}
</script>

<style scoped>
  #web-design-expert-container {
    position: relative;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 1rem auto;
    color: white;
    z-index: 15;
    width: 50%;
  }

  #fam-headshot {
    display: block;
    width: 75%;
    max-width: 225px;
  }

  #web-design-credits {
    font-weight: bolder;
    font-size: 1rem;
    text-align: center;
    margin: 0;
  }

  @media screen and (min-width: 950px) {
    #web-design-expert-container {
      width: 26%;
      padding-left: 1rem;
    }

    #fam-headshot {
      width: 100%;
    }

    #web-design-credits {
      font-size: 1.25rem;
    }
  }
</style>