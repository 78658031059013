<template>
  <div id="main" v-if="isUnlocked() || passwordEntered">
    <Lessonalyzer />
    <Compliance />
    <div id="fire-container">&nbsp;</div>
  </div>
<!--  <div v-else id="screenshot-container">-->
<!--    <ScreenshotCity />-->
<!--  </div>-->
  <div v-else id="pw-container">
    <h1>This site is password protected.</h1>
    <form id="pw-form" @submit.prevent="testPassword">
      <label>
        Password
        <input type="password" id="pw" name="pw">
      </label>
      <button type="submit">
        Enter
      </button>
    </form>
  </div>
</template>

<script>
import Lessonalyzer from '@/components/Lessonalyzer.vue'
import Compliance from "@/components/content/Compliance";
// import ScreenshotCity from "@/components/utilities/ScreenshotCity";

export default {
  name: 'App',
  components: {
    Compliance,
    Lessonalyzer,
    // ScreenshotCity
  },
  data() {
    return {
      passwordEntered: null !== window.localStorage.getItem('granted')
    }
  },
  methods: {
    isUnlocked() {
      const now = new Date();
      const unlocked = new Date('March 25, 2021 20:30:00 GMT-0700');
      return now >= unlocked;
    },

    testPassword() {
      if(document.getElementById('pw').value.toLowerCase() === "paradise") {
        window.localStorage.setItem('granted', '1')
        this.passwordEntered = true
      }
    },
  },
}
</script>

<style>
body {
  /* Creates a fallback background color */
  background: rgb(2,8,21);
  /* Generates the gradient for the background */
  background: linear-gradient(
      180deg,
      rgb(2,8,21) 8%,
      rgb(8, 11, 47) 92%
    ) fixed;
  margin: 0;
}

#main {
  position: relative;
}

#pw-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  background: rgba(127,127,127,0.5);
  padding: 1rem;
  color: white;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

#pw-container > h1 {
  margin: 0 0 1rem 0;
}

#pw {
  font-size: 16px;
}

#app {
  background-image: url("./assets/stars-seamless.png");
  background-repeat: repeat;
  background-size: contain;

  font-family: "Times New Roman", serif;

  height: 100vh;
  width: 100%;
  z-index: -1;

  overflow-x: hidden!important;
}

#fire-container {
  z-index: 1;
  position: fixed;
  bottom: 0;
  width: 100%;
  background: url("assets/flaming-liner.gif") repeat-x;
  background-size: auto 100%;
  height: 30px;
}
</style>
