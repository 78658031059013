<template>
  <div id="pupa-coin-container">
    <img
        id="bad-gateway"
        src="../../assets/bad-gateway.png"
        alt="Error 420 bad gateway"
    />
    <img
        id="alert-emoji"
        src="../../assets/emoji.png"
        alt="Alert emoji"
    />
    <img
        id="accepting-pupa-coin-mobile"
        src="../../assets/pupa-coin-mobile.gif"
        alt="Now accepting pupa coin"
    />
    <img
        id="accepting-pupa-coin-desktop"
        src="../../assets/pupa-coin-desktop.gif"
        alt="Now accepting pupa coin"
    />
  </div>
</template>

<script>
export default {
  name: "PupaCoinAlert"
}
</script>

<style scoped>
  #pupa-coin-container {
    position: relative;
    z-index: 15;
  }

  #bad-gateway {
    display: block;
    width: 100%;
  }

  #alert-emoji {
    width: auto;
    max-height: 25px;
    position: absolute;
    top: -5px;
    left: -5px;
  }

  #accepting-pupa-coin-mobile,
  #accepting-pupa-coin-desktop {
    width: auto;
    position: absolute;
  }

  #accepting-pupa-coin-mobile {
    max-height: 100px;
    top: -50%;
    right: -15px;
  }

  #accepting-pupa-coin-desktop {
    display: none;
  }

  @media screen and (min-width: 950px) {
    #alert-emoji {
      max-height: 50px;
      top: -20px;
      left: -15px;
    }

    #pupa-coin-container {
      position: relative;
      bottom: 0;
      width: 50%;
    }
    #accepting-pupa-coin-mobile {
      display: none;
    }
    #accepting-pupa-coin-desktop {
      display: initial;
      top: 40%;
      right: 0;
      transform: translate(120px, -50%);
      max-height: 150px;
    }
  }
</style>