<template>
  <div id="main-container">
    <div id="solar-opposites-header">
      <img
          id="header-img"
          src="../assets/header-image.png"
          alt="Welcome to my web-site for The Solar Opposites."
      />
    </div>
    <div id="content-flex-container">
      <div style="margin-bottom: 5px; width: 100%;">
        <CautionRule height="6px" bar-width="15" />
      </div>
      <div id="content-inner-flex-container">
        <LessonalyzerButton />
        <div id="bottom-caution-rule" style="margin-top: 5px; width: 100%;">
          <CautionRule height="6px" bar-width="15" />
        </div>
        <KorvoAd />
        <PrivateLink />
        <WebDesignExpert />
      </div>
      <PupaCoinAlert />
    </div>
  </div>
</template>

<script>
import LessonalyzerButton from "@/components/content/LessonalyzerButton";
import CautionRule from "@/components/utilities/CautionRule";
import KorvoAd from "@/components/content/KorvoAd";
import PrivateLink from "@/components/content/PrivateLink";
import WebDesignExpert from "@/components/content/WebDesignExpert";
import PupaCoinAlert from "@/components/content/PupaCoinAlert";

export default {
  name: "Lessonalyzer",
  components: {
    PupaCoinAlert,
    PrivateLink,
    LessonalyzerButton,
    CautionRule,
    WebDesignExpert,
    KorvoAd,
  }
}
</script>

<style scoped>
  #solar-opposites-header {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;

    margin: 1.25rem 0 0.85rem;
  }

  #header-img {
    display: block;
    width: 100%;
  }

  #main-container {
    width: 85%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    z-index: 15;
    position: relative;
  }

  #content-flex-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  #content-inner-flex-container {
    width: 100%;
  }

  @media screen and (min-width: 950px) {
    #main-container {
      width: 90%;
    }

    #content-inner-flex-container {
      display: flex;
      justify-content: center;
    }

    #content-inner-flex-container > :nth-child(1) {
      order: 2;
    }

    #content-inner-flex-container > :nth-child(3) {
      order: 1;
    }

    #content-inner-flex-container > :nth-child(5) {
      order: 3;
    }

    #header-img {
      width: 60%;
    }

    #bottom-caution-rule {
      display: none;
    }
  }
</style>